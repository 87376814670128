import './App.css';
import Header from './componenet/Header';
import Article from './componenet/Article';
import Crousal from './componenet/Crousal';
import Footer from './componenet/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
// import VerifyEmail from './componenet/VerifyEmail';
// import EmailSent from './componenet/EmailSent';

function App() {
  // const [appEmail,setAppEmail] = useState('')
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Article />
              <Crousal />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

const Layout = ({ children }) => {
  return (
    <div className="App">
      <Header />
      {children}
      <Footer />
    </div>
  );
};
