import React from 'react'

function Footer() {
  return (
    <footer>
        <hr size={"2px"} /> 
        <p class="footer-copyright" >Journal Club &copy; 2022</p>
    </footer>
  )
}

export default Footer