import React,{useState,useEffect} from 'react'
import header_img from '../Assets/header.png';
function Header() {
  const [buttonTop, setButtonTop] = useState(null);
  const LOGIN_URL = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
  const SIGNUP_URL = `${process.env.REACT_APP_AWS_DOMAIN}/signup?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;

  useEffect(() => {
    updateButtonTop();
    window.addEventListener('resize', updateButtonTop);
    window.addEventListener('load', updateButtonTop);
    return () => {
      window.removeEventListener('resize', updateButtonTop);
      window.addEventListener('load', updateButtonTop);
    };
  }, []);

  const updateButtonTop = () => {
    const element = document.getElementById('hero');
    const widthScreen = window?.screen?.availWidth
    if (element) {
      const startPoint = element.clientHeight;
      if(startPoint > 150 || widthScreen < 1000){
        setButtonTop(startPoint - 4);
      }
    }
  };

  return (
    <header className='landing-page-heading sticky' id="hero">
        <div style={{display:'flex'}}>
        <img src={header_img} alt="jc-header"></img>
        </div>
        <div className="button_container-desktop">
        <div className="button_wraper">
          <a href={LOGIN_URL} className="button">
            LOGIN
          </a>
          <a href={SIGNUP_URL} className="button">
            SIGN UP
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header