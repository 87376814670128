import React from 'react';

function Article() {
  const LOGIN_URL = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
  const SIGNUP_URL = `${process.env.REACT_APP_AWS_DOMAIN}/signup?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;

  return (
    <>
      <div className="content_wraper content">
        <p className="jc-bodycopy hide-desktop" style={{ marginTop: '34px' }}>
          Already signed up but haven’t verified your email address?{' '}
          <span>
            <a
              href={`${process.env.REACT_APP_URL}/resend-email`}
              className="jc-red"
            >
              Verify Now
            </a>
          </span>{' '}
          or send an email to{' '}
          <a
            className="jc-red"
            href={`mailto:${process.env.REACT_APP_COGNITO_VERIFY_EMAIL}`}
          >
            {process.env.REACT_APP_COGNITO_VERIFY_EMAIL}
          </a>{' '}
          with the subject as{' '}
          <span style={{ fontWeight: '600' }}>"Verify Me"</span>
        </p>
        <h1 className="jc-landing-page-heading" style={{ marginTop: '5%' }}>
          Share medical insights with institutions and experts of your choice
        </h1>
        <p className="jc-landing-page-paragraph">
          The first networked platform where institutions, associations and
          individual experts can create and disseminate ongoing monetizable
          content on any medical or biomedical topic.
        </p>
      </div>
      <div className="button_container-mobile">
        <div className="button_wraper">
          <a href={LOGIN_URL} className="button">
            LOGIN
          </a>
          <a href={SIGNUP_URL} className="button">
            SIGN UP
          </a>
        </div>
      </div>
      <div className="content_wraper hide-mobile">
        <p className="jc-bodycopy" style={{ marginTop: '14px' }}>
          Already signed up but haven’t verified your email address?{' '}
          <span>
            <a
              href={`${process.env.REACT_APP_URL}/resend-email`}
              className="jc-red"
            >
              Verify Now
            </a>{' '}
            or send an email to{' '}
            <a
              className="jc-red"
              href={`mailto:${process.env.REACT_APP_COGNITO_VERIFY_EMAIL}`}
            >
              {process.env.REACT_APP_COGNITO_VERIFY_EMAIL}
            </a>{' '}
            with the subject as{' '}
            <span style={{ fontWeight: '600' }}>"Verify Me"</span>
          </span>
        </p>
      </div>
    </>
  );
}

export default Article;
