import React, { useEffect, useState } from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';

import image1 from '../Assets/img_1.svg';
import image2 from '../Assets/img_2.svg';
import image3 from '../Assets/img_3.svg';
import { ReactComponent as VideoIcon } from '../Assets/VideoIcon.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const SlIDE_TITLES = {
  CDA: {
    title: 'Course Directors & Administrators',
    subtitle:
      'Take a tour to see how your custom educational platform can curate, create, network and distribute multimedia content.',
  },
  CONTR: {
    title: 'Contributors',
    subtitle:
      'See how you can efficiently identify new research and quickly create content while collaborating and sharing insights with colleagues around the world.',
  },
  READER: {
    title: 'Readers',
    subtitle:
      'Be a virtual member in educational activities from departments, associations and experts. Make your free feed on your interests from journals you choose.',
  },
};

const settings = {
  navigation: true,
  items: 1,
  center: true,
  loop: true,
  autoplay: true,
  autoplaySpeed: 2000,
  slideSpeed: 5000,
  navText: ['&#10094;', '&#10095;'],
  responsive: {
    0 : {
      items: 1,
      nav: true,
      dots: true,
    },
    767: {
      nav: true,
      dots: true,
      items: 3
    }
  }
};

function Crousal() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxStart, setLightboxStart] = useState(0);

  const handleCrousalClick = (id) => {
    setLightboxStart(id);
    setLightboxOpen(true);
  };

  useEffect(() => {
    modifyLighbox()
  }, [lightboxOpen]);

  const modifyLighbox = () => {
    const iframeNode = document.getElementsByTagName('iframe');
    if (iframeNode && iframeNode[0]) {
      iframeNode[0].width = '80%';
      iframeNode[0].height = '80%';
    }
  }
  return (
    <>
      <div className="crousal-wraper">
        <div className="crousal-container">
          <OwlCarousel {...settings} className="screenshot_slide">
            <div
              className="crousal-slide"
              onClick={() => handleCrousalClick(0)}
            >
              <div style={{ position: 'relative' }}>
                <img className="crousal-image" src={image2} alt="jc-img-1" />
                <VideoIcon />
              </div>
              <SliderTitle sliderType={SlIDE_TITLES.CONTR} />
            </div>
            <div
              className="crousal-slide"
              onClick={() => handleCrousalClick(1)}
            >
              <div style={{ position: 'relative' }}>
                <img className="crousal-image" src={image1} alt="jc-img-2" />
                <VideoIcon />
              </div>

              <SliderTitle sliderType={SlIDE_TITLES.READER} />
            </div>
            <div
              className="crousal-slide"
              onClick={() => handleCrousalClick(2)}
            >
              <div style={{ position: 'relative' }}>
                <img className="crousal-image" src={image3} alt="jc-img-3" />
                <VideoIcon />
              </div>

              <SliderTitle sliderType={SlIDE_TITLES.CDA} />
            </div>
          </OwlCarousel>
        </div>
      </div>

      {lightboxOpen && (
        <div className="lightbox-custom">
          <ReactImageVideoLightbox
            data={[
              {
                url: '/JC%20Contributor%20Capabilities.mp4',
                type: 'video',
                title: 'For Contributors',
              },
              {
                url: '/JC%20Reader%20Capabilities.mp4',
                type: 'video',
                title: 'For Reader',
              },
              {
                url: '/JC%20Course%20Diretor%20+%20Administrator%20Capabilities.mp4',
                type: 'video',
                title: 'For Course Director and Administrator',
              },
            ]}
            startIndex={lightboxStart}
            showResourceCount={true}
            onCloseCallback={() => setLightboxOpen(false)}
            onNavigationCallback={(currentIndex) =>
              modifyLighbox()
            }
          ></ReactImageVideoLightbox>
        </div>
      )}
    </>
  );
}

export default Crousal;

const SliderTitle = ({ sliderType }) => {
  return (
    <div className="slide-title">
      <div className="jc-landing-page-paragraph slider-heading">
        <strong>{sliderType.title}</strong>
      </div>
      <div className="jc-landing-page-paragraph slider-info">
        {sliderType.subtitle}
      </div>
    </div>
  );
};
